import { Grid, Typography } from '@material-ui/core'
import Image from 'next/image'
import styled from 'styled-components'
import Flickity from 'react-flickity-component'
import { irem } from '../../utils'

export const StyledCardDesignImageContainer = styled.div`
  width: 100%;
  height: ${props => props.theme.typography.pxToRem(199)};

  ${props => props.theme.breakpoints.up('sm')} {
    height: ${props => props.theme.typography.pxToRem(217)};
  }

  > div {
    position: relative !important;
    height: 100%;
  }
`

export const StyledImage = styled(Image)`
  display: inline-block;
  width: 100%;
  position: relative;
  transition: all 0.5s ease;
`

export const StyledCarouselItemContainer = styled(Grid)`
  width: 33%;
  position: relative;
  flex: 1;

  &:hover {
    cursor: pointer;

    ${StyledImage} {
      transform: scale(1.05);
    }
  }

  &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: ${props => props.theme.typography.pxToRem(10)};
    height: ${props => props.theme.typography.pxToRem(5)};
    width: 90%;
    margin-left: ${props => props.theme.typography.pxToRem(20)};
    background-color: #f2f2f2;

    ${props => props.theme.breakpoints.up('sm')} {
      width: 84%;
      left: ${props => props.theme.typography.pxToRem(32)};
      margin-left: 0;
    }
  }
`

export const StyledDate = styled(Typography)`
  font-size: ${props => props.theme.typography.pxToRem(14)};
  padding-top: ${props => props.theme.typography.pxToRem(12)};
  font-weight: 500;
  text-align: center;
  ${props=> props.theme.breakpoints.up('sm')} {
    font-size: ${props => props.theme.typography.pxToRem(15)};
    padding-top: ${props => props.theme.typography.pxToRem(38)};
    text-align: left;
  }
`

export const StyledTitle = styled(Typography)`
  padding-top: ${props => props.theme.typography.pxToRem(23)};
  font-size: ${props => props.theme.typography.pxToRem(20)};
  font-weight: 500;
  text-align: left;
  max-height: ${props => props.theme.typography.pxToRem(190)};
  margin-bottom: 0;

  ${props=> props.theme.breakpoints.up('sm')} {
    padding-top: ${props => props.theme.typography.pxToRem(31)};
    font-size: ${props => props.theme.typography.pxToRem(24)};
    text-align: left;
    overflow-y: auto;
  }
`

export const StyledLinkTitle = styled.a`
  display: block;
  text-decoration: none;
  width: 100%;
  color: ${props => props.theme.palette.text.primary};
  padding-bottom: ${irem(46)};
  padding-left: ${props => props.theme.typography.pxToRem(20)};
  padding-right: ${props => props.theme.typography.pxToRem(20)};

  ${props=> props.theme.breakpoints.up('sm')} {
    padding-left: 0;
    padding-right: 0;
  }

  &:hover {
    cursor: pointer;

    > ${StyledTitle} {
      color: ${props => props.theme.palette.secondary.main};
    }
  }
`

export const StyledDescription = styled(Typography)`
  font-size: ${props => props.theme.typography.pxToRem(16)};
  text-align: left;
  padding-top: ${props => props.theme.typography.pxToRem(23)};
  max-height: ${props => props.theme.typography.pxToRem(190)};

  ${props=> props.theme.breakpoints.up('sm')} {
    text-align: left;
    padding-top: ${props => props.theme.typography.pxToRem(14)};
    overflow-y: auto;
  }
`

export const StyledFlickityComponent = styled(Flickity)<{$columns: number; $hasNavigation: boolean}>`
  position: relative;
  outline: none;
  width: 100%;

  .flickity-button {
    display: none;
  }

  ${props => props.theme.breakpoints.up('sm')} {
    .flickity-prev-next-button {
      top: ${props => props.theme.typography.pxToRem(125)};
      width: ${props => props.theme.typography.pxToRem(90)};
      height: ${props => props.theme.typography.pxToRem(90)};
      padding: ${props => props.theme.typography.pxToRem(28)};
      border-radius: 50%;
      box-shadow: unset;
      transform: translateY(-50%);
      border: 1px black solid;
      -webkit-backface-visibility: hidden;
      background: none;

      & > svg {
        width: ${props => props.theme.typography.pxToRem(25)};
        height: ${props => props.theme.typography.pxToRem(25)};
      }

      &:focus {
        outline: ${props => props.theme.typography.pxToRem(1)} auto -webkit-focus-ring-color;
      }
    }

    .previous {
      left: 0;
      ${props => props.theme.breakpoints.up('md')} {
        left: ${props => props.theme.typography.pxToRem(-153)};
      }
    }

    .next {
      right: ${props => props.theme.typography.pxToRem(0)};
      ${props => props.theme.breakpoints.up('md')} {
        right: ${props => props.theme.typography.pxToRem(-153)};
      }
    }
  }

  ${props => props.theme.breakpoints.up('md')} {
    .flickity-button {
      display: ${props => props.$hasNavigation ? 'block' : 'none'};
      position: absolute;
      color: ${props=> props.theme.palette.text.primary};

      &:hover {
        cursor: pointer;

        > .flickity-button-icon {
          fill: ${props=> props.theme.palette.primary.main};
        }
      }
    }
  }

  .flickity-viewport {
    overflow-x: hidden;
    overflow-y: hidden;

    &:focus {
      outline: 0 !important;
    }

    .flickity-slider {
      height: 100%;

      ${props => props.theme.breakpoints.up('sm')} {
        > ${StyledCarouselItemContainer} {
          padding-left: ${props => props.theme.typography.pxToRem(30)};
          padding-right: ${props => props.theme.typography.pxToRem(30)};
        }
      }

      ${props => props.theme.breakpoints.up('md')} {
        > ${StyledCarouselItemContainer}:nth-child(odd), ${StyledCarouselItemContainer}:nth-child(even) {
          padding-left: ${props => props.theme.typography.pxToRem(32)};
          padding-right: ${props => props.theme.typography.pxToRem(32)};
        }
      }

    }
  }

  .flickity-page-dots {
    position: absolute;
    width: 100%;
    display: ${props => props.$hasNavigation ? 'block' : 'none'};
    left: 50%;
    right: 0;
    padding: 0;
    list-style: none;
    text-align: center;
    line-height: 1;
    transform: translateX(-50%);
    margin-top: ${props => props.theme.typography.pxToRem(43)};
    ${props => props.theme.breakpoints.up('sm')} {
      margin-top: ${props => props.theme.typography.pxToRem(70)};
    }

    li {
      margin: 0;

      &::before {
        display: none;
      }
    }

    .dot {
      display: inline-block;
      width: ${props => props.theme.typography.pxToRem(10)};
      height: ${props => props.theme.typography.pxToRem(10)};
      margin: 0 ${props => props.theme.typography.pxToRem(8)};
      background: #333;
      border-radius: 50%;
      opacity: 0.25;
      cursor: pointer;

      &.is-selected {
        opacity: 1;
        color: ${props => props.theme.palette.primary.main};
        background: ${props => props.theme.palette.primary.main};
      }
    }
  }
`

export const StyledCarouselContainer = styled.div`
  width: 100%;
`